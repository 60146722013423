var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [_c("q-btn-group", { attrs: { outline: "" } })],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            label: "점검유형",
                            disabled: true,
                            codeGroupCd: "MDM_CHECK_KIND_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "equipmentCheckKindCd",
                          },
                          model: {
                            value: _vm.result.equipmentCheckKindCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "equipmentCheckKindCd", $$v)
                            },
                            expression: "result.equipmentCheckKindCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "MIM_CHECK_RESULT_CD",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            required: "",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "checkResultCd",
                            label: "점검결과",
                          },
                          model: {
                            value: _vm.result.checkResultCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkResultCd", $$v)
                            },
                            expression: "result.checkResultCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            label: "실제점검자",
                            name: "checkUserId",
                            type: "user",
                          },
                          model: {
                            value: _vm.result.checkUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkUserId", $$v)
                            },
                            expression: "result.checkUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "실제점검일",
                            name: "checkDate",
                          },
                          model: {
                            value: _vm.result.checkDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkDate", $$v)
                            },
                            expression: "result.checkDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-moc", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "MOC 번호",
                            name: "sopMocId",
                          },
                          model: {
                            value: _vm.result.sopMocId,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "sopMocId", $$v)
                            },
                            expression: "result.sopMocId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "점검결과요약",
                            name: "checkResultSummary",
                          },
                          model: {
                            value: _vm.result.checkResultSummary,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkResultSummary", $$v)
                            },
                            expression: "result.checkResultSummary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-tab", {
            attrs: {
              type: "tabcard",
              tabItems: _vm.tabItems,
              inlineLabel: true,
              align: "left",
            },
            on: {
              "update:tabItems": function ($event) {
                _vm.tabItems = $event
              },
              "update:tab-items": function ($event) {
                _vm.tabItems = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (tab) {
                  return [
                    _c(tab.component, {
                      tag: "component",
                      attrs: {
                        imprTabInfo: _vm.imprTabInfo,
                        research: _vm.research,
                        result: _vm.result,
                      },
                      on: {
                        "update:imprTabInfo": function ($event) {
                          _vm.imprTabInfo = $event
                        },
                        "update:impr-tab-info": function ($event) {
                          _vm.imprTabInfo = $event
                        },
                        "update:research": function ($event) {
                          _vm.research = $event
                        },
                        "update:result": function ($event) {
                          _vm.result = $event
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.editable && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: { flat: true, showLoading: false, label: "완료" },
                      on: { btnClicked: _vm.resultComplete },
                    })
                  : _vm._e(),
                _vm.editable && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: true,
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.result,
                        mappingType: "PUT",
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveInspection,
                        btnCallback: _vm.saveCallback,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }